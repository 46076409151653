exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-awareness-month-js": () => import("./../../../src/pages/awarenessMonth.js" /* webpackChunkName: "component---src-pages-awareness-month-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cyber-security-month-archive-js": () => import("./../../../src/pages/cyberSecurityMonthArchive.js" /* webpackChunkName: "component---src-pages-cyber-security-month-archive-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-get-safe-online-js": () => import("./../../../src/pages/getSafeOnline.js" /* webpackChunkName: "component---src-pages-get-safe-online-js" */),
  "component---src-pages-org-index-js": () => import("./../../../src/pages/org_index.js" /* webpackChunkName: "component---src-pages-org-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-search-page-js": () => import("./../../../src/pages/searchPage.js" /* webpackChunkName: "component---src-pages-search-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-awareness-month-pages-js": () => import("./../../../src/templates/awarenessMonth-pages.js" /* webpackChunkName: "component---src-templates-awareness-month-pages-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-page-list-js": () => import("./../../../src/templates/page-list.js" /* webpackChunkName: "component---src-templates-page-list-js" */),
  "component---src-templates-tags-page-list-js": () => import("./../../../src/templates/tags-page-list.js" /* webpackChunkName: "component---src-templates-tags-page-list-js" */),
  "slice---src-components-footer-com-js": () => import("./../../../src/components/FooterCom.js" /* webpackChunkName: "slice---src-components-footer-com-js" */),
  "slice---src-components-nav-bar-com-js": () => import("./../../../src/components/NavBarCom.js" /* webpackChunkName: "slice---src-components-nav-bar-com-js" */),
  "slice---src-components-side-bar-com-js": () => import("./../../../src/components/SideBarCom.js" /* webpackChunkName: "slice---src-components-side-bar-com-js" */)
}

